const bootstrapValidation = (formSelector, validationRules = {}, errors) => {
  const formGroupClass = ".form-group";
  const formControlClass = ".form-control";
  const validationMessageClass = ".validation-message";
  const formGroups = $(`${formSelector} ${formGroupClass}`);
  const visitedClass = "visited";
  const inputTouchedClass = "touched";
  const invalidClass = "invalid";
  const noValueClass = "no-value";
  const runValidationAttribute = "data-run-validation";

  const handleErrorMessage = (inputElement, validationMessages) => {
    const event = new CustomEvent('FairgoValidation', {detail: validationMessages});
    inputElement.dispatchEvent(event);
    
    inputElement.setCustomValidity(validationMessages[0] || "");
  };

  const checkCustomValidity = (inputElement, formGroupElement) => {
    const funcThatReturnsEmptyArray = () => ([]);
    const otherInputsWithinFormGroupElement = formGroupElement.querySelectorAll(formControlClass);
    const formGroupControlClasses = formGroups.map(formGroup => {
      return formGroup.querySelectorAll(formControlClass)
    });
    const validationMessagesGetter = (validationRules[inputElement.name] || funcThatReturnsEmptyArray);
    const validationMessages = validationMessagesGetter(inputElement, otherInputsWithinFormGroupElement, formGroupControlClasses);
    
    handleErrorMessage(inputElement, validationMessages);
  };

  const updateInputValidityInDom = (inputElement, formGroupElement) => {
    const isInputValid = inputElement.checkValidity();
    const validationMessageElementList = formGroupElement.querySelectorAll(validationMessageClass);
    
    if (isInputValid) {
      formGroupElement.classList.remove(invalidClass);
    } else {
      formGroupElement.classList.add(invalidClass);
    }

    validationMessageElementList.forEach((validationMessageElement) => {
      validationMessageElement.innerHTML = inputElement.validationMessage
    });
  };

  const modifyValidationClass = (inputElement, formGroupElement) => {
    if (inputElement.hasAttribute(runValidationAttribute)) {
      checkCustomValidity(inputElement, formGroupElement);
      updateInputValidityInDom(inputElement, formGroupElement);
    }
  };

  const modifyVisitedClass = (inputElement, formGroupElement) => {
    inputElement.classList.add(inputTouchedClass);
    const allFormControlsHaveBeenTouched = formGroupElement
      .querySelectorAll(formControlClass)
      .every(fc => fc.classList.contains(inputTouchedClass));
    
    if (allFormControlsHaveBeenTouched) {
      formGroupElement.classList.add(visitedClass);
    }
  };

  const modifySelectStateClass = (inputElement) => {
    const {nodeName, value} = inputElement;
    if (nodeName === "SELECT") {
      if (value === null || typeof value === undefined || value === "") {
        inputElement.classList.add(noValueClass)
      } else {
        inputElement.classList.remove(noValueClass)
      }
    }
  };

  formGroups.on("focusout", ({target: inputElement, currentTarget: formGroupElement}) => {
    modifyVisitedClass(inputElement, formGroupElement);
    modifyValidationClass(inputElement, formGroupElement);
    modifySelectStateClass(inputElement);
  });

  formGroups.on("input", ({target: inputElement, currentTarget: formGroupElement}) => {
    modifyValidationClass(inputElement, formGroupElement);
  });

  const onFormSubmitValid = (formInputElementNodeList, formElement) => {
    formInputElementNodeList.forEach((formGroupElement) => {
      formGroupElement.classList.remove(visitedClass);
      formGroupElement.classList.remove(invalidClass);
    });

    if (formElement.hasAttribute("data-disable-on-submit")) {
      const submitButton = formElement.querySelector("button[type=\"submit\"]");
      submitButton.setAttribute("disabled", "true");
    }
  };

  const onFormSubmitInValid = (event, formInputElementNodeList) => {
    event.preventDefault();
    formInputElementNodeList.forEach((formGroupElement) => {
      formGroupElement.classList.add(visitedClass);
      const [inputElement] = formGroupElement.querySelectorAll(`[${runValidationAttribute}]`);
      modifyValidationClass(inputElement, formGroupElement);
    });
  };

  $(formSelector).on("submit", (event) => {
    const { currentTarget: formElement } = event;
    const formInputElementNodeList = formElement.querySelectorAll(formGroupClass);
    
    // update all validation prior to submit
    // this is required as some validation can rely on other
    // field values which might have changed
    formInputElementNodeList.forEach((formInputElement) => {
      formInputElement.querySelectorAll(formControlClass).forEach((inputElement) => {
        checkCustomValidity(inputElement, formInputElement);
      });
    });
    
    if (!formElement.checkValidity()) {
      onFormSubmitInValid(event, formInputElementNodeList);
      return;
    }
    onFormSubmitValid(formInputElementNodeList, formElement);
  });


  const bootstrapErrors = () => {
    formGroups.forEach((formGroupElement) => {
      const inputElements = formGroupElement.querySelectorAll(`[${runValidationAttribute}]`);

      inputElements.forEach((inputElement) => {
        if (inputElement.hasAttribute(runValidationAttribute)) {
          const inputName = inputElement.name;
          const errorsSafe = errors || {};
          const errorForInput = (errorsSafe[inputName] || [])[0] || "";

          if (errorForInput !== "") {
            inputElement.setCustomValidity(errorForInput);
            formGroupElement.classList.add(visitedClass)
          }

          updateInputValidityInDom(inputElement, formGroupElement);
          modifySelectStateClass(inputElement);
        }
      });
    });
  };
  bootstrapErrors();
};

window.bootstrapValidation = bootstrapValidation;