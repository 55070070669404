const ancestorAttribute = "data-ancestor-"
const targetForAttribute = "data-target-for"

const getAttributesStartingWithAncestor = (target) => Object.values(target.attributes)
  .filter((attr) => attr.name.startsWith(ancestorAttribute));

const getTargetsForTargetedElement = (targetForElement) => {
  const targetForValue = targetForElement.getAttribute(targetForAttribute);
  const targetForTargets = targetForValue.split(" ");
  return targetForTargets;
};

const getClassListUpdater = (attr) => {
  const toggleableAction = attr.name.replace(ancestorAttribute, "");

  const addAttributePart = "add-";
  if (toggleableAction.startsWith(addAttributePart)) {
    const toggleableClass = toggleableAction.replace(addAttributePart, "");
    const classListUpdater = (e, className) => e.classList.add(className);
    
    return {
      toggleableClass,
      classListUpdater
    }
  }
  const removeAttributePart = "remove-";
  if (toggleableAction.startsWith(removeAttributePart)) {
    const toggleableClass = toggleableAction.replace(removeAttributePart, "");
    const classListUpdater = (e, className) => e.classList.remove(className);
    
    return {
      toggleableClass,
      classListUpdater
    }
  }


  return {
    toggleableClass: toggleableAction, 
    classListUpdater: (e, className) => {
      e.classList.toggle(className);
    }
  };
};

const bootstrapTogglePassword = () => {
  const showPasswordAttribute = "data-show-password";
  $(`[${showPasswordAttribute}]`).on("click", ({currentTarget}) => {
    const toggleTargetSelector = currentTarget.getAttribute(showPasswordAttribute);
    const toggleTargetElementNodeList = $(toggleTargetSelector);

    toggleTargetElementNodeList.forEach(toggleTargetElement => {
      if (toggleTargetElement.getAttribute("type") === "password") {
        toggleTargetElement.type = "text";
      } else {
        toggleTargetElement.type = "password";
      }
    });
  });
};

const bootstrapTargetForAttributes = () => {
  $(`[${targetForAttribute}]`).on("click", (event) => {
    const {target, currentTarget: targetedElement} = event;

    const attributesStartingWithAncestor = getAttributesStartingWithAncestor(target, ancestorAttribute);

    attributesStartingWithAncestor.forEach((attr) => {
      const {toggleableClass, classListUpdater} = getClassListUpdater(attr);

      const targetForTargets = getTargetsForTargetedElement(targetedElement);

      if (targetForTargets.includes(toggleableClass)) {
        classListUpdater(targetedElement, toggleableClass)
      }
    });
  });
};

window.docReady(() => {
  bootstrapTargetForAttributes();
  bootstrapTogglePassword();
});